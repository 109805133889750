a {
  text-decoration: none;
  color: inherit;
}

.App {
  width: 100%;
}

.gradient_top {
  height: 70px;
  background: linear-gradient(#2563FF, #FFFFFF);
}

.home_firm_info {
  margin: auto;
  text-align: center;
  padding: 25px 15px;
}

.home_firm_logo {
  width: 170px;
}

.home_firm_name {
  margin: 0;
  font-size: 28px;
  font-weight: 600;
}

.home_firm_desc {
  margin: 10px 0 0 0;
  font-size: 15px;
}

.home_firm_geo {
  margin: 15px auto 0 auto;
  padding: 5px 15px;
  border-radius: 100px;
  display: flex;
  flex-direction: row;
  width: fit-content;
  background-color: rgba(37, 99, 255, .25);
}

.home_firm_geo_loc {
  margin: 0;
  color: #2563FF;
  align-content: center;
  width: auto;
  font-size: 14px;
  font-weight: 600;
}

.home_menu_list {
  margin: 70px auto 0 auto;
}

.home_menu_header {
  margin: auto;
  width: fit-content;
}

.home_menu_divider {
  margin: 0 auto;
  width: 50%;
  color: #D9D9D9;
}

.home_menu_container {
  padding: 30px 20px 30px 20px;
  width: auto;
  margin: auto;
  text-align: center;
}

.home_menu_container p {
  margin: 0;
}

.home_menu_subhead {
  padding: 0 10px;
  font-size: 16px;
  font-weight: 400;
}

.home_menu_mainhead {
  padding: 0 10px;
  font-size: 28px;
  font-weight: 700;
  padding: 7px 0 5px 0;
}

.home_menu_desc {
  padding: 0 10px;
  font-size: 15px;
  font-weight: 400;
  color: #505050;
}

.home_menu_image {
  padding: 15px 0 0 0;
  width: 100%;
}

.home_menu_button {
  margin-top: 25px;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  padding: 15px 30px;
  border-radius: 100px;
  border: none;
  background-color: #2563FF;
}

.home_footer {
  display: flex;
  flex-direction: column;
  padding: 170px 30px 40px 30px;
}

.home_footer_logo {
  height: 40px;
}

.home_footer_desc {
  padding: 10px 0 10px 0;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
}

.home_footer_mailto {
  width: fit-content;
  margin: auto;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  padding: 15px 30px;
  border-radius: 100px;
  border: none;
  background-color: #9539FF;
}

@media screen and (max-width: 500px) {
  
}