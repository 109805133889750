

.Drinks {
    width: 100%;
}

.drinks_header {
    padding: 50px 0 35px 0;
    text-align: center;
    background: linear-gradient(#2563FF, #10276D);
}

.drinks_firm_logo {
    width: 100px;
    height: auto;
}

.drinks_firm_name {
    margin: 0;
    font-size: 20px;
    color: #ffffff;
    font-weight: 700;
}

.drinks_firm_desc {
    margin: 7px 0 5px 0;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
}

.drinks_list {
    margin: 45px auto;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
}

.drinks_list_header {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 10px 0;
    width: 100%;
}

.drinks_item {
    padding: 13px 15px 13px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.40);
    border-radius: 7px;
}

.drinks_item_name {
    font-size: 15px;
}

.drinks_item_pricing {
    display: flex;
    justify-content: center;
    align-items: center;
}

.drinks_item_price {
    font-size: 22px;
    font-weight: 700;
    color: #2563FF;
    margin-right: 5px;
}

.drinks_item_price_lv {
    font-size: 15px;
    font-weight: 700;
    color: #2563FF;
}